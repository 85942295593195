import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { Container, Wrap } from "./defaultComponents"

const Footer = () => {
  return (
    <Wrap>
      <Content>
        <Column>
          <Bold>Links</Bold>
          <FooterLinkExtern
            href="https://www.xing.com/profile/Wiebke_Klingsporn"
            rel="noopener"
            target="_blank"
          >
            Xing
          </FooterLinkExtern>
          <br />
          <br />

          <FooterLinkIntern to="/impressum">Impressum</FooterLinkIntern>
          <br />
          <FooterLinkIntern to="/datenschutz">
            Datenschutzerklärung
          </FooterLinkIntern>
        </Column>
        <Column>
          © {new Date().getFullYear()} Wiebke Klingsporn. <br /> Alle Rechte
          vorbehalten.
        </Column>
        <Column>
          <StaticImage
            src="../images/logo.png"
            alt="Wiebke Klingsporn Logo"
            loading="lazy"
            placeholder="tracedSVG"
          />
        </Column>
      </Content>
    </Wrap>
  )
}

const Content = styled(Container)`
  display: grid;
  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: left;
    grid-template-columns: repeat(3, 1fr);
    padding: 20px 0;
    align-items: center;
  }
`

const Column = styled.div`
  @media screen and (max-width: 768px) {
    &:first-child {
      order: 1;
    }

    &:nth-child(2) {
      order: 2;
    }

    &:last-child {
      order: 0;
    }
  }
`

const Bold = styled.p`
  font-weight: bold;
`

const FooterLinkIntern = styled(Link)`
  text-decoration: none;
  color: var(--white);
`

const FooterLinkExtern = styled.a`
  text-decoration: none;
  color: var(--white);
`

export default Footer
