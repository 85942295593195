import { createGlobalStyle } from "styled-components"
import Mariachi from "../fonts/Mariachi-Regular.woff"

export const GlobalStyle = createGlobalStyle`

  :root {
    --primary-color: #e7b18d;
    --secondary-color: #b96a4e;
    --text-color: #6e7480;
    --white: #6e7480;
    font-size: 16px;
    line-height: 1.6;
  }

  @font-face {
    font-family: 'Mariachi-Regular';
    src: url(${Mariachi}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    color: var(--text-color);
    max-width: 100vw;

  }

  body {
    font-family: brevia, sans-serif;
    max-width: 100vw;
  }

  h1, h2  {
    font-family: "Mariachi-Regular", serif;
    margin-bottom: 30px;
    font-weight: light;
    font-style: normal;
  }

`
